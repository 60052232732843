import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import HeroImg from "../images/peca.svg";
import Header from "../components/Header";
import Footer from "../components/Footer";

const MainContents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WidthLimiter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
`;

const AboutContainer = styled.article`
  padding: 0 32px;
  margin-top: 10vh;

  .section__detail {
    color: gray;
  }
  h3 {
    text-indent: 1.6rem;
    &.newChallange:after {
      content: "";
      display: block;
      width: 28px;
      border-bottom: 1px solid white;
    }
  }
  .about__sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    .about__section {
      padding-top: 60px;
      &:last-child {
        grid-column: 2/3;
        @media screen and (max-width: 970px) {
          grid-column: 1/3;
        }
      }
      @media screen and (max-width: 970px) {
        grid-column: 1/3;
      }
      &:nth-child(2n) {
        @media screen and (max-width: 970px) {
          grid-column: 2/3;
          margin-top: -100px;
        }
      }
    }
  }
  a {
    color: white;
  }
  .contact {
    margin-top: 80px;
    opacity: 0.6;
    & > a h3 {
      @media screen and (max-width: 970px) {
        text-indent: 0;
      }
    }
  }
  .links {
    display: flex;
  }
  .about__workExprience {
    margin-top: 2rem;
    .workExprience__detail {
      p {
        margin-bottom: 1rem;
        ul {
          margin-left: 1.5rem;
          padding-left: 1.5rem;
          list-style: disc;
          display: flex;
          flex-wrap: wrap;
          li {
            margin-right: 2rem;
            span {
              margin-left: -1rem;
            }
          }
        }
      }
    }
  }
  .about_whoAmI {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    @media screen and (max-width: 970px) {
      grid-template-columns: repeat(2, 1fr);
    }
    p {
      color: white;
      font-size: 1rem;
      ul {
        padding-left: 0.5rem;
        li {
          &:nth-child(4) {
            margin-left: -0.5rem;
          }
        }
      }
    }
    div {
      width: 100%;
      overflow: hidden;
    }
    .whoAmI__image {
      img {
        height: 100%;
        object-fit: cover;
        max-height: 310px;
        @media screen and (max-width: 600px) {
          max-height: 200px;
          max-width: 100%;
        }
        filter: invert(90%);
      }
    }
    .whoAmI__description {
      align-self: center;
      padding-left: 0.5rem;
    }
    .whoAmI__mark {
      margin-top: 2rem;
      padding-left: 1rem;
      font-size: 1.2rem;
      grid-row: 2/3;
      grid-column: 1/3;
      margin: 3rem 0;
      @media screen and (max-width: 970px) {
        text-align: right;
      }
    }
  }
`;

const About = () => {
  return (
    <>
      <MainContents>
        <WidthLimiter>
          <Header pageTitle="About"></Header>
          <AboutContainer>
            <h1>
              Juhyoung Lee<small>designer, frontend developer</small>
            </h1>
            <div className="about_whoAmI">
              <div className="whoAmI__description">
                <p>
                  I'm a
                  <ul>
                    <li>frontend designer,</li>
                    <li>graphic designer,</li>
                    <li>motion designer,</li>
                    <li>and</li>
                    <li>frontend developer.</li>
                  </ul>
                  <br />
                </p>
              </div>
              <div className="whoAmI__image">
                <img src={HeroImg} alt="who am i"></img>
              </div>
              <div className="whoAmI__mark">
                A designer who can perform well in a wide area.
                <br />
                幅広い領域で活躍できるデザイナー。
              </div>
            </div>
            <div className="about__workExprience">
              <h2>Work experience</h2>
              <div className="workExprience__detail">
                <h3>
                  CS-C _ graphic, web designer<small>01.2019~recent</small>
                </h3>
                <p>
                  <ul>
                    <li>
                      <span>graphic design</span>
                    </li>
                    <li>
                      <span>asset design</span>
                    </li>
                    <li>
                      <span>web design</span>
                    </li>
                    <li>
                      <span>UI prototyping</span>
                    </li>
                    <li>
                      <span>web programming</span>
                    </li>
                    <li>
                      <span>web programming</span>
                    </li>
                    <li>
                      <span>motion design</span>
                    </li>
                  </ul>
                </p>
              </div>
              <div className="workExprience__detail">
                <h3>
                  freelance designer<small>04.2015~03.2020</small>
                </h3>
                <p>
                  <ul>
                    <li>
                      <span>graphic design</span>
                    </li>
                    <li>
                      <span>asset design</span>
                    </li>
                    <li>
                      <span>web design</span>
                    </li>
                    <li>
                      <span>web programming</span>
                    </li>
                    <li>
                      <span>motion design</span>
                    </li>
                  </ul>
                </p>
              </div>
              <div className="workExprience__detail">
                <h3>
                  VCNC _ graphic designer<small>03.2014~12.2014</small>
                </h3>
                <p>
                  <ul>
                    <li>
                      <span>graphic design</span>
                    </li>
                    <li>
                      <span>asset design</span>
                    </li>
                    <li>
                      <span>creative planning</span>
                    </li>
                  </ul>
                </p>
              </div>
              <div className="workExprience__detail">
                <h3>
                  freelance designer<small>03.2011~02.2014</small>
                </h3>
                <p>
                  <ul>
                    <li>
                      <span>graphic design</span>
                    </li>
                    <li>
                      <span>asset design</span>
                    </li>
                    <li>
                      <span>web design</span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <ul className="about__sections">
              <li className="about__section">
                <h2>Skills 🎨</h2>
                <ul className="section__detail">
                  <li>Visual design</li>
                  <li>Graphic design</li>
                  <li>Motion design</li>
                  <li>Web Interface design</li>
                  <li>-</li>
                  <li>Frontend developing</li>
                </ul>
              </li>
              <li className="about__section">
                <h2>Tools 🖥</h2>
                <ul className="section__detail">
                  <li>Illustrator</li>
                  <li>Photoshop</li>
                  <li>Premiere pro, After effact</li>
                  <li>Adobe Xd, figma</li>
                  <li>-</li>
                  <li>html, css, javascript, python</li>
                  <li>react, nodejs, wordpress</li>
                </ul>
              </li>
              <li className="about__section">
                <h2>Language 🔤</h2>
                <ul className="section__detail">
                  <li>Korean - mother tongue</li>
                  <li>Japanese - JLPT N1</li>
                  <li>English</li>
                </ul>
              </li>
              <li className="about__section">
                <h2>Studying 🖋</h2>
                <ul className="section__detail">
                  <li>blender 3D</li>
                  <li>Django</li>
                  <li>React</li>
                  <li>-</li>
                  <li>English</li>
                  <li>Chinese</li>
                </ul>
              </li>
              <li className="about__section">
                <h2>Interested in 🛹</h2>
                <ul className="section__detail">
                  <li>Video game</li>
                  <li>Photography</li>
                  <li>Videography</li>
                  <li>Skateboarding</li>
                  <li>Programming</li>
                </ul>
                <h3 className="newChallange">and New Challange</h3>
              </li>
            </ul>
            <div className="contact">
              <a href="mailto:studiopeca.lee@gmail.com">
                <h3>E-mail : studiopeca.lee@gmail.com</h3>
              </a>
              <ul className="links">
                <li>
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/in/juhyoung-lee-b7064a19b/"
                  >
                    <h3>
                      <i class="fab fa-linkedin"></i>
                    </h3>
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://github.com/PecaLee">
                    <h3>
                      <i class="fab fa-github-square"></i>
                    </h3>
                  </Link>
                </li>
              </ul>
            </div>
          </AboutContainer>
          <Footer></Footer>
        </WidthLimiter>
      </MainContents>
    </>
  );
};

export default About;
